<template>
  <v-row class="login my-0 mx-auto">
    <v-col cols="12" lg="2"></v-col>
    <v-col lg="4" md="6" class="pa-0 d-flex align-center justify-center">
      <v-card elevation="0" max-width="340" width="100%" rounded="lg" class="mx-auto my-4 px-md-0 px-2">
        <div class="h1 text-center mb-5">Welcome back</div>
        <v-form @submit.prevent="login" ref="form" lazy-validation>
          <div class="h10">Email</div>
          <v-text-field
            class="field44"
            v-model="email"
            :error-messages="emailErrors"
            placeholder="Enter email"
            outlined
            dense
            required
            clearable
            clear-icon="mdi-close-circle"
            color="secondary"
            height="44"
          ></v-text-field>
          <div class="h10 mt-n2">Password</div>
          <v-text-field
            class="field44"
            v-model="password"
            :error-messages="passwordErrors"
            placeholder="Enter password"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
            outlined
            dense
            required
            color="secondary"
            height="44"
          ></v-text-field>
          <div class="text-center mb-3">
            <v-btn depressed type="submit" max-width="340" width="100%" large color="primary">LOG IN</v-btn>
          </div>
          <div class="h9 text-center">
            <router-link to="/forgot-password">Forgot your password?</router-link>
          </div>
          <div class="d-flex align-center my-5">
            <v-divider></v-divider>
            <div class="mx-5 text-body-2 text--text">or</div>
            <v-divider></v-divider>
          </div>
          <div class="text-center">
            <v-btn @click="signIn" depressed max-width="340" width="100%" large outlined color="text">
              <div class="btn-google">
                <v-img max-width="18" height="18" contain src="@/assets/icon/google.svg"></v-img>
                <div class="h7">Log in with Google</div>
              </div>
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </v-col>
    <v-col cols="6" class="pa-0 d-sm-block d-none img"></v-col>
    <v-dialog v-model="modalError" width="380">
      <v-card width="100%" class="px-10 pt-13 pb-10 text-center">
        <div class="h8 mb-8">The email or password you entered is incorrect</div>
        <v-btn @click="modalError = false" color="primary" height="34" width="140">I UNDERSTAND</v-btn>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      email: '',
      password: '',
      show: false,
      modalError: false,
      error: [],
    };
  },
  mounted() {
    const _onInit = (auth2) => {
      console.log('init OK', auth2);
    };
    const _onError = (err) => {
      console.log('error', err);
    };
    window.gapi.load('auth2', () => {
      window.gapi.auth2
        .init({
          client_id: process.env.VUE_APP_GOOGLE,
          scope: 'email profile openid',
          fetch_basic_profile: false,
        })
        .then(_onInit, _onError);
    });
  },
  methods: {
    signIn() {
      const auth2 = window.gapi.auth2.getAuthInstance();
      auth2.signIn().then((googleUser) => {
        const access_token = googleUser.getAuthResponse().access_token;
        console.log('ID Token: ' + access_token);
        this.$store.dispatch('social', { token: access_token, provider: 'google' }).then((res) => {
          sessionStorage.setItem('jwt_token', res.data.jwt_token);
          sessionStorage.setItem('social', 'google');
          this.$router.push('/');
        });
      });
    },
    signOut() {
      const auth2 = window.gapi.auth2.getAuthInstance();
      auth2.signOut().then(() => {
        console.log('User signed out.');
      });
    },
    async login() {
      this.error = [];
      await this.$store
        .dispatch('signIn', { email: this.email, password: this.password })
        .then((res) => {
          sessionStorage.setItem('jwt_token', res.data.jwt_token);
          this.$router.replace(this.$route.query.from || '/');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.error.find((item) => item == 'credential__invalid') ? (this.modalError = true) : '';
        });
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push('Please enter your email');
      this.error.find((item) => item == 'email__invalid') && errors.push('Provided email is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      this.error.find((item) => item == 'password__required') && errors.push('Please enter your password');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
};
</script>

<style lang="scss">
.login {
  // max-width: 1150px;
  // width: 100%;
  .img {
    width: 50vw;
    height: calc(100vh - 69px);
    background: url(../assets/img/pattern.svg);
  }
  .btn-google {
    width: 100%;
    position: relative;
    .v-image {
      position: absolute;
      left: 0;
    }
  }
}
</style>
